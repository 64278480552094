import { ElementType } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import LoadingSpinner from '@/components/atoms/LoadingSpinner';
import cn, { Argument } from 'classnames';

export type ButtonVariant = 'primary' | 'secondary' | 'tertiary' | 'fourth';

export interface ButtonProps extends React.ComponentProps<'button'> {
  children: React.ReactNode;
  variant?: ButtonVariant;
  size?: 'base' | 'sm';
  disabled?: boolean;
  loading?: boolean;
  icon?: IconProp;
  fullWidth?: boolean;
  iconComponent?: React.ReactNode;
  as?: ElementType;
}

type VariantConfig = {
  classNames: Argument[];
};

export const Button = ({
  children,
  disabled,
  loading,
  variant = 'primary',
  fullWidth = true,
  icon,
  size = 'base',
  className,
  iconComponent,
  as = 'button',
  ...buttonProps
}: ButtonProps) => {
  const isButtonEnabled = !disabled && !loading;

  const variantConfig: Record<ButtonVariant, VariantConfig> = {
    primary: {
      classNames: [
        {
          'font-semibold text-[16px] leading-[24px] tracking-normal bg-sumawealth-yellow-80 rounded-full font-work-sans':
            true,
          'text-xs md:text-sm px-2 sm:px-8 h-[46px] md:h-[48px]': size === 'base',
          'text-xxs md:text-xs px-1 sm:px-4 h-[24px] md:h-[30px]': size === 'sm',
          'w-full': fullWidth,
        },
        isButtonEnabled
          ? 'hover:bg-sumawealth-yellow-60 text-sumawealth-blue-100 focus:bg-sumawealth-yellow-100'
          : 'bg-sumawealth-grey-20 text-sumawealth-grey-100',
      ],
    },
    secondary: {
      classNames: [
        {
          'font-semibold text-[16px] leading-[24px] tracking-normal bg-sumawealth-neutral-0 rounded-full font-work-sans border-2 border-sumawealth-blue-80':
            true,
          'text-xs md:text-sm px-2 sm:px-8 h-[46px] md:h-[48px]': size === 'base',
          'text-xxs md:text-xs px-1 sm:px-4 h-[24px] md:h-[30px]': size === 'sm',
          'w-full': fullWidth,
        },
        isButtonEnabled
          ? 'text-sumawealth-blue-80 hover:border-sumawealth-blue-60 hover:text-sumawealth-blue-60 focus:border-sumawealth-blue-100 focus:text-sumawealth-blue-100'
          : 'text-sumawealth-grey-100 border-sumawealth-grey-100',
      ],
    },
    tertiary: {
      classNames: [
        {
          'font-semibold text-[16px] leading-[24px] tracking-normal bg-sumawealth-yellow-80 rounded-full font-work-sans':
            true,
          'text-xs md:text-sm px-2 sm:px-8 h-[46px] md:h-[48px]': size === 'base',
          'text-xxs md:text-xs px-1 sm:px-4 h-[24px] md:h-[30px]': size === 'sm',
          'w-full': fullWidth,
        },
        isButtonEnabled
        ? 'text-sumawealth-blue-80 hover:border-sumawealth-blue-60 hover:text-sumawealth-blue-60 focus:border-sumawealth-blue-100 focus:text-sumawealth-blue-100'
        : 'text-sumawealth-grey-100 border-sumawealth-grey-100',
      ],
    },
    fourth: {
      classNames: [
        {
          'font-semibold text-[16px] leading-[24px] tracking-normal bg-sumawealth-neutral-0 rounded-full font-work-sans border-2 border-sumawealth-blue-80':
            true,
          'text-xs md:text-sm px-2 sm:px-8 h-[46px] md:h-[48px]': size === 'base',
          'text-xxs md:text-xs px-1 sm:px-4 h-[24px] md:h-[30px]': size === 'sm',
          'w-full': fullWidth,
        },
        isButtonEnabled
          ? 'text-sumawealth-neutral-0 hover:border-sumawealth-blue-60 hover:text-sumawealth-neutral-0 focus:border-sumawealth-blue-100 focus:text-sumawealth-blue-100'
          : 'text-sumawealth-grey-100 border-sumawealth-grey-100',
      ],
    }
  };

  const { classNames } = variantConfig[variant];

  const Component = as || 'button';

  return (
    //@ts-ignore
    <Component
      className={cn(
        'relative select-none transition flex-shrink-0 flex items-center justify-center',
        classNames,
        disabled && 'cursor-not-allowed !bg-sumawealth-grey-20 !text-sumawealth-grey-100',
        loading && 'cursor-wait',
        className
      )}
      type="button"
      disabled={!isButtonEnabled}
      {...buttonProps}
    >
      {icon && !iconComponent && !loading && (
        <div className="fa-lg pr-3">
          <FontAwesomeIcon icon={icon} />
        </div>
      )}
      {iconComponent && !loading && <div className="fa-lg pr-3">{iconComponent}</div>}
      <span className="inline-grid auto-cols-max grid-flow-col items-center gap-2 sm:gap-4">
        {loading && <LoadingSpinner size="sm" />}
        {children}
        {/* Keeps the text centered */}
        {loading && (
          <span className="inline-grid h-0 opacity-0">
            <LoadingSpinner size="sm" />
          </span>
        )}
      </span>
    </Component>
  );
};
