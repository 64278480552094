import { useEffect } from 'react';

export default function useRemoveLocalStorage({ enabled }: { enabled: boolean }) {
  useEffect(() => {
    const removeLocalStorage = async () => {
      if (enabled) {
        await localStorage.clear();
      }
    };

    removeLocalStorage();
  }, [enabled]);
}
