import AuthTemplate from '@/components/templates/AuthTemplate';
import Head from 'next/head';
import { NextPageWithLayout } from './_app';
import React from 'react';
import Signup from '@/components/pages/Signup';
import { withTranslate } from '@/hoc/withTranslate';

const SignupPage: NextPageWithLayout = () => {
  return (
    <>
      <Head>
        <title>Sign Up | Suma</title>
      </Head>

      <Signup />
    </>
  );
};

SignupPage.getLayout = (page: React.ReactElement) => <AuthTemplate>{page}</AuthTemplate>;

export const getServerSideProps = withTranslate(async (context, ssg) => {
  if (context.req.cookies.appSession) {
    return {
      props: {
        trpcState: ssg.dehydrate(),
      },
      redirect: {
        destination: '/sumaversity',
        permanent: false,
      },
    };
  }

  return {
    props: {
      trpcState: ssg.dehydrate(),
    },
  };
}, 'Sign Up');

export default SignupPage;
