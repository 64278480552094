import React from 'react';
import { SignupFormPageContainer } from '@/components/organisms/SignupForm/SignupForm';
import { useRouter } from 'next/router';
import useRemoveLocalStorage from '@/hooks/useRemoveLocalStorage';

interface SignupProps extends React.HTMLAttributes<HTMLDivElement> {}

const Signup: React.FC<SignupProps> = () => {
  const router: any = useRouter();
  const { redirectTo, error } = router.query as { redirectTo?: string; error?: string };

  useRemoveLocalStorage({ enabled: true });

  const onSuccess = () => {
    router.replace(redirectTo || '/sumaversity');
  };

  return (
    <SignupFormPageContainer
      onSuccess={onSuccess}
      error={error}
      promocode={{
        code: router.query?.code,
        partner: router.query?.partner,
      }}
    />
  );
};

export default Signup;
