import AppTemplate from '../AppTemplate';
import coffee from '@/images/coffee.webp';
import Image from 'next/image';
import React from 'react';
import { useRouter } from 'next/router';
import quinceañera from '@/images/quinceañera.webp';
import VerticalLayout from '@/components/layouts/VerticalLayout';

export interface AuthTemplateProps extends React.HTMLAttributes<HTMLDivElement> {}

interface ImageByRouteProps {
  pathname: string;
}

const IMAGES: any = {
  '/signup': quinceañera,
  '/login': coffee,
  '/forgot-password': coffee,
};

const ImageByRoute: React.FC<ImageByRouteProps> = ({ pathname }) => {
  return <Image src={IMAGES[pathname]} alt="Suma lucha money" priority />;
};

const AuthTemplate: React.FC<AuthTemplateProps> = ({ children }) => {
  const router = useRouter();
  return (
    <AppTemplate>
      <VerticalLayout className="container relative justify-center rounded-3xl bg-sumawealth-neutral-0">
        <div className="invisible absolute left-10 rounded-2xl bg-sumawealth-grey-20 md:h-[40rem] md:w-[20rem] xl:visible xl:h-[33rem] 2xl:visible"></div>
        <div className="grid w-full auto-cols-fr grid-flow-col">
          <div className="hidden h-full items-center justify-center pb-5 lg:flex">
            <ImageByRoute pathname={router.pathname} />
          </div>
          <div className="flex h-full items-center justify-center">
            <div className="flex w-full flex-col items-center justify-center gap-8 lg:mx-12 lg:p-12">
              {children}
            </div>
          </div>
        </div>
      </VerticalLayout>
    </AppTemplate>
  );
};

export default AuthTemplate;
