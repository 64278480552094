import React from 'react';
import classNames from 'classnames';
import Typography from '../@next/Typography';
export interface FormAlertMessageProps {
  children: React.ReactNode | string;
  variant: 'error' | 'warning' | 'success';
  withBackground?: boolean;
  icon?: React.ReactNode;
  justifyCenter?: boolean;
  typographyClassName?: string; 
}
export const FormAlertMessage = ({ children, variant, withBackground = false, icon, justifyCenter, typographyClassName }: FormAlertMessageProps) => {
  return (
    <div
      className={classNames('flex w-full items-center p-3 rounded-lg', {
        'bg-sumawealth-error-20': variant === 'error' && withBackground,
        'bg-sumawealth-warning-20': variant === 'warning' && withBackground,
        'bg-sumawealth-info-20': variant === 'success' && withBackground,
        'justify-center': justifyCenter
      })}
    >
      {icon && icon}
      <Typography
        size="16px"
        variant="body"
        className={classNames(
          {
            'text-sumawealth-error-60': variant === 'error',
            'text-sumawealth-info-60': variant === 'success',
            'text-sumawealth-warning-60': variant === 'warning',
          },
          'whitespace-pre-wrap !font-medium',
          typographyClassName
        )}
      >
        {typeof children === 'string' ? (
          <span dangerouslySetInnerHTML={{ __html: children }} />
        ) : (
          children
        )}
      </Typography>
    </div>
  );
};