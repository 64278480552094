import { ButtonProps, ButtonVariant } from '@/components/atoms/@next/Button/Button';

import Button from '@/components/atoms/@next/Button';
import { FormAlertMessage } from '@/components/atoms/FormAlertMessage';
import React from 'react';
import VerticalLayout from '@/components/layouts/VerticalLayout';

export interface FormSubmitButtonProps extends Omit<ButtonProps, 'variant'> {
  error?: string | null | undefined;
  variant?: ButtonVariant;
  errorVariant?: 'error' | 'warning' | 'success' | undefined;
}

const FormSubmitButton: React.FC<FormSubmitButtonProps> = ({
  error,
  variant = 'primary',
  errorVariant = 'error',
  ...buttonProps
}) => {
  return (
    <VerticalLayout>
      {error && !buttonProps.loading && (
        <FormAlertMessage variant={errorVariant}>{error}</FormAlertMessage>
      )}

      <Button type="submit" variant={variant} {...buttonProps}></Button>
    </VerticalLayout>
  );
};

export default FormSubmitButton;
